

import React from "react";
import heroImage from "../../assets/img/background.jpg";
import WhoWeAre from "./WhoWeAre";
import Blog from "./Newslist";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";
import Blogs from "../Pages/Blogs";



const Hero = () => {
  return (
    <section>
    <div
      className="relative bg-cover h-[400px] md:h-[728px] text-white"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,41,83,0.8)0%] to-[rgba(0, 45, 91, 0.8) 100%] flex flex-col justify-between pt-10 pb-[250px] md:pb-[100px]">
        
        <div className="text-center px-18">
          <h1 className="px-2 text-4xl font-bold mb-4 md:text-3xl">
            Welcome to Birtamod Futsal
          </h1>
          <p className="hidden md:block text-lg md:px-52 mb-4 md:text-base" style={{textAlign:'justify'}}>
          Welcome to Birtamod Futsal, the ultimate destination for futsal enthusiasts in Birtamode! As a proud locally-owned facility, we offer a modern playing surface, top-notch equipment, and a friendly environment suited for players of all skill levels. Whether you're here to hone your techniques, stay active, or simply enjoy a great time with friends, Birtamod Futsal has you covered.

Our spacious grounds are designed to accommodate both casual players and competitive leagues, making it an ideal spot for tournaments, events, or just a friendly match. With convenient facilities, ample parking, and a vibrant community, we strive to make your experience seamless and enjoyable.

Our dedicated staff is committed to making every match safe, fair, and fun, ensuring that you feel welcome from the moment you step onto the pitch. Discover why we’re the leading futsal venue in Birtamode, and let us be your home for great games and lasting memories. Join us today and be part of the action!
          </p>
          <Link to="/signup">
          <button className="bg-transparent text-white border border-yellow-500 px-4 py-2 rounded hover:bg-slate-700 text-lg md:text-sm">
            Sign Up
          </button>
          </Link>
        </div>
      </div>
      </div>
      <WhoWeAre />
      
      <Blogs />
      
      <ContactForm/>
    </section>

  );
};

export default Hero;

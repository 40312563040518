import axios from 'axios';
export const apiURL='https://app.birtamodesportscenter.com/'
const apiClient = axios.create({
  baseURL: 'http://localhost:5000', // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchPopupData = async () => {
  try {
    const response = await apiClient.get('/popup'); // Replace with the API endpoint
    return response.data;
  } catch (error) {
    console.error('Error fetching popup data:', error);
    throw error;
  }
};

import React from "react";
import Adriana from "../../assets/img/Adriana.png";
import Sandor from "../../assets/img/Sandor.png";
import Karolis from "../../assets/img/Karolis.png";
import Noname from "../../assets/img/Noname.png";

const teamMembers = [
  {
    image: Adriana,
  },
  // {
  //   image: Sandor,
  // },
  // {
  //   image: Karolis,
  // },
  // {
  //   image: Noname,
  // },
];

const WhoWeAre = () => {
  return (
    <div className="flex flex-col lg:flex-row items-start p-10 lg:p-32">
      <div className="flex-1 lg:pr-8">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="text-gray-500 text-sm md:text-lg leading-relaxed mb-4"  style={{textAlign:'justify'}}>
          Welcome to Birtamod Futsal, the ultimate destination for futsal
          enthusiasts in Birtamode! As a proud locally-owned facility, we offer
          a modern playing surface, top-notch equipment, and a friendly
          environment suited for players of all skill levels. Whether you're
          here to hone your techniques, stay active, or simply enjoy a great
          time with friends, Birtamod Futsal has you covered. Our spacious
          grounds are designed to accommodate both casual players and
          competitive leagues, making it an ideal spot for tournaments, events,
          or just a friendly match. With convenient facilities, ample parking,
          and a vibrant community, we strive to make your experience seamless
          and enjoyable. Our dedicated staff is committed to making every match
          safe, fair, and fun, ensuring that you feel welcome from the moment
          you step onto the pitch. Discover why we’re the leading futsal venue
          in Birtamode, and let us be your home for great games and lasting
          memories. Join us today and be part of the action!
        </p>
      </div>
      <div className="hidden md:block md:flex-2 md:grid-cols-2 gap-6 mt-10 lg:mt-0">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`flex flex-col items-center text-center 
              ${index === 1 ? "mt-12" : ""} 
              ${index === 2 ? "-mt-12" : ""}`}
          >
            <img
              src={member.image}
              alt="image"
              className="w-[228px] h-[315px] rounded-lg mb-3 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhoWeAre;


// import React from "react";
// import Adriana from "../../assets/img/Adriana.png";
// import Sandor from "../../assets/img/Sandor.png";
// import Karolis from "../../assets/img/Karolis.png";
// import Noname from "../../assets/img/Noname.png";

// const teamMembers = [
//   {
//     image: Adriana,
//   },
//   {
//     image: Sandor,
//   },
//   {
//     image: Karolis,
//   },
//   {
//     image: Noname,
//   },
// ];

// const WhoWeAre = () => {
//   return (
//     <div className="flex flex-col lg:flex-row items-start p-6 md:p-10 lg:p-20 gap-10">
//       {/* About Us Section */}
//       <div className="flex-1">
//         <h2 className="text-2xl md:text-3xl font-bold mb-4">About Us</h2>
//         <p className="text-gray-500 text-base md:text-lg leading-relaxed mb-6">
//           Welcome to Birtamod Futsal, the ultimate destination for futsal
//           enthusiasts in Birtamode! As a proud locally-owned facility, we offer
//           a modern playing surface, top-notch equipment, and a friendly
//           environment suited for players of all skill levels. Whether you're
//           here to hone your techniques, stay active, or simply enjoy a great
//           time with friends, Birtamod Futsal has you covered. Our spacious
//           grounds are designed to accommodate both casual players and
//           competitive leagues, making it an ideal spot for tournaments, events,
//           or just a friendly match. With convenient facilities, ample parking,
//           and a vibrant community, we strive to make your experience seamless
//           and enjoyable. Our dedicated staff is committed to making every match
//           safe, fair, and fun, ensuring that you feel welcome from the moment
//           you step onto the pitch. Discover why we’re the leading futsal venue
//           in Birtamode, and let us be your home for great games and lasting
//           memories. Join us today and be part of the action!
//         </p>
//       </div>

//       {/* Team Members Section */}
//       <div className="flex-2 grid grid-cols-2 gap-6 sm:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
//         {teamMembers.map((member, index) => (
//           <div
//             key={index}
//             className={`flex flex-col items-center text-center 
//               ${index === 1 ? "mt-6 sm:mt-8 lg:mt-12" : ""} 
//               ${index === 2 ? "-mt-6 sm:-mt-8 lg:-mt-12" : ""}`}
//           >
//             <img
//               src={member.image}
//               alt={`Team member ${index + 1}`}
//               className="w-40 h-60 sm:w-[200px] sm:h-[280px] lg:w-[228px] lg:h-[315px] rounded-lg mb-3 object-cover"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WhoWeAre;

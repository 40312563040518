// import { Button } from "flowbite-react";
// import { ErrorMessage, Field, Form, Formik } from 'formik';
// import React, { useState } from "react";

// import { IoEyeOffSharp, IoEyeOutline } from "react-icons/io5";
// import { NavLink } from "react-router-dom";
// import * as Yup from 'yup';
// const SignUp = () => {
//   const [showPassword1, setShowPassword1] = useState(false);
//   const [showPassword2, setShowPassword2] = useState(false);
//   const togglePasswordVisibility1 = () => {
//     setShowPassword1(!showPassword1);
//   };
//   const togglePasswordVisibility2 = () => {
//     setShowPassword2(!showPassword2);
//   };
//   return (
//     <section className="bg-gray-100 ">
      
//       <div className="flex justify-center items-center h-screen ">
//         <div className="p-6 shadow-lg rounded-md w-[520px] bg-white ">
//           <h5 className="font-semibold text-[32px] leading-[38px] text-[#1A1A1A] text-center mb-[20px]">
//             Create Account
//           </h5>
//           <Formik
//             initialValues={{
//               email: '',
//               password: '',
//               confirmPassword: '',
//               termsAccepted: false
//             }}
//             validationSchema={Yup.object({
//               email: Yup.string()
//                 .email('Invalid email address')
//                 .required('Email is required'),
//               password: Yup.string()
//                 .min(6, 'Password must be at least 6 characters')
//                 .required('Password is required'),
//               confirmPassword: Yup.string()
//                 .oneOf([Yup.ref('password'), null], 'Passwords must match')
//                 .required('Confirm Password is required'),
//               termsAccepted: Yup.boolean()
//                 .oneOf([true], 'You must accept the terms & conditions')
//                 .required('You must accept the terms & conditions')
//             })}
//             onSubmit={(values, { setSubmitting }) => {
//               setTimeout(() => {
//                 // alert(JSON.stringify(values, null, 2));
//                 setSubmitting(false);
//               }, 400);
//             }}
//           >
//             <Form>
//               <div className="flex flex-col gap-[16px]">
//                 <div>
//                   <label htmlFor="email">Email</label>
//                   <Field
//                     type="email"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     className="border border-[#E6E6E6] rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
//                   />
//                   <ErrorMessage name="email" component="div" className="text-red-500" />
//                 </div>
//                 <div>
//                   <label htmlFor="password">Password</label>
//                   <div className="relative">
//                     <Field
//                       type={showPassword1 ? "text" : "password"}
//                       id="password"
//                       name="password"
//                       placeholder="Password"
//                       className="border rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
//                     />
//                     <button
//                       type="button"
//                       onClick={togglePasswordVisibility1}
//                       className="absolute inset-y-0 right-0 flex items-center pr-3"
//                     >
//                       {showPassword1 ? (
//                         <IoEyeOffSharp className="h-5 w-5 text-gray-400" />
//                       ) : (
//                         <IoEyeOutline className="h-5 w-5 text-gray-400" />
//                       )}
//                     </button>
//                   </div>
//                   <ErrorMessage name="password" component="div" className="text-red-500" />
//                 </div>
//                 <div>
//                   <label htmlFor="confirmPassword">Confirm Password</label>
//                   <div className="relative">
//                     <Field
//                       type={showPassword2 ? "text" : "password"}
//                       id="confirmPassword"
//                       name="confirmPassword"
//                       placeholder="Confirm Password"
//                       className="border rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
//                     />
//                     <button
//                       type="button"
//                       onClick={togglePasswordVisibility2}
//                       className="absolute inset-y-0 right-0 flex items-center pr-3"
//                     >
//                       {showPassword2 ? (
//                         <IoEyeOffSharp className="h-5 w-5 text-gray-400" />
//                       ) : (
//                         <IoEyeOutline className="h-5 w-5 text-gray-400" />
//                       )}
//                     </button>
//                   </div>
//                   <ErrorMessage name="confirmPassword" component="div" className="text-red-500" />
//                 </div>
//                 <div className="flex items-center">
//                   <Field
//                     type="checkbox"
//                     id="termsAccepted"
//                     name="termsAccepted"
//                     className="mr-2"
//                   />
//                   <label htmlFor="termsAccepted">Accept all terms & conditions</label>
//                   <ErrorMessage name="termsAccepted" component="div" className="text-red-500" />
//                 </div>
//               </div>
//               <div className="my-4">
//                 <Button
//                   type="submit"
//                   className="w-full bg-blue-200 rounded-full hover:bg-green-700 hover:text-[#1A1A1A] text-blue-700"
//                 >
//                   Create Account
//                 </Button>
//               </div>
//             </Form>
//           </Formik>
//           <div className="flex items-center justify-center gap-1 text-[#666666] text-[14px] leading[21px] font-normal">
//             <p>Already have an account?</p>
//             <NavLink
//               to="/signin"
//               className={({ isActive }) =>
//                 isActive ? "text-green-500" : "text-[#1A1A1A] font-medium"
//               }
//             >
//               <a>Login</a>
//             </NavLink>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
// export default SignUp;

import { Button } from "flowbite-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";

import { IoEyeOffSharp, IoEyeOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";

const SignUp = () => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <section className="bg-gray-100 pt-4">
      <div className="flex justify-center items-center h-screen">
        <div className="p-6 shadow-lg rounded-md w-[520px] bg-white">
          <h5 className="font-semibold text-[32px] leading-[38px] text-[#1A1A1A] text-center mb-[20px]">
            Create Account
          </h5>
          <Formik
            initialValues={{
              username: "",
              email: "",
              phone: "",
              address: "",
              password: "",
              confirmPassword: "",
              termsAccepted: false,
            }}
            validationSchema={Yup.object({
              username: Yup.string()
                .min(3, "Username must be at least 3 characters")
                .required("Username is required"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
              phone: Yup.string()
                .matches(/^\d{10}$/, "Phone number must be 10 digits")
                .required("Phone number is required"),
              address: Yup.string().required("Address is required"),
              password: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm Password is required"),
              termsAccepted: Yup.boolean()
                .oneOf([true], "You must accept the terms & conditions")
                .required("You must accept the terms & conditions"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2)); // Display form data (can be replaced with API call)
                setSubmitting(false);
              }, 400);
            }}
          >
            <Form>
              <div className="flex flex-col gap-[16px]">
                <div>
                  <label htmlFor="username">Username</label>
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    className="border border-[#E6E6E6] rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  />
                  <ErrorMessage name="username" component="div" className="text-red-500" />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="border border-[#E6E6E6] rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500" />
                </div>
                <div>
                  <label htmlFor="phone">Phone Number</label>
                  <Field
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="border border-[#E6E6E6] rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  />
                  <ErrorMessage name="phone" component="div" className="text-red-500" />
                </div>
                <div>
                  <label htmlFor="address">Address</label>
                  <Field
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Address"
                    className="border border-[#E6E6E6] rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  />
                  <ErrorMessage name="address" component="div" className="text-red-500" />
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <div className="relative">
                    <Field
                      type={showPassword1 ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="border rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility1}
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showPassword1 ? (
                        <IoEyeOffSharp className="h-5 w-5 text-gray-400" />
                      ) : (
                        <IoEyeOutline className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage name="password" component="div" className="text-red-500" />
                </div>
                <div>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="relative">
                    <Field
                      type={showPassword2 ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="border rounded-md w-full px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility2}
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showPassword2 ? (
                        <IoEyeOffSharp className="h-5 w-5 text-gray-400" />
                      ) : (
                        <IoEyeOutline className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage name="confirmPassword" component="div" className="text-red-500" />
                </div>
                <div className="flex items-center">
                  <Field
                    type="checkbox"
                    id="termsAccepted"
                    name="termsAccepted"
                    className="mr-2"
                  />
                  <label htmlFor="termsAccepted">Accept all terms & conditions</label>
                  <ErrorMessage name="termsAccepted" component="div" className="text-red-500" />
                </div>
              </div>
              <div className="my-4">
                <Button
                  type="submit"
                  className="w-full bg-blue-200 rounded-full hover:bg-green-700 hover:text-[#1A1A1A] text-blue-700"
                >
                  Create Account
                </Button>
              </div>
            </Form>
          </Formik>
          <div className="flex items-center justify-center gap-1 text-[#666666] text-[14px] leading[21px] font-normal">
            <p>Already have an account?</p>
            <NavLink
              to="/signin"
              className={({ isActive }) =>
                isActive ? "text-green-500" : "text-[#1A1A1A] font-medium"
              }
            >
              <a>Login</a>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../Card/Card';
import { apiURL } from '../../api/apiService';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Blog = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get(`${apiURL}feeds/home-feed`)
      .then(response => {
        setNews(response.data.blogs);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <section className="text-center my-10 md:px-[100px] px-1 mx:auto">
      <div className="mx-auto mb-10">
        <h2 className="text-2xl font-bold mb-4">Blogs</h2>
        <p className="text-gray-500 md:max-w-2xl mx-auto">
          Football related blogs. Read about the happenings in sports world.
        </p>
      </div>
      <div className="grid grid-cols-1 md:flex md:flex-wrap justify-center md:gap-4 my-6 md:px-6">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={2}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {news.map((item, index) => (
            <SwiperSlide key={index}>
              <Card
                title={item.title.rendered}
                date={item.date.slice(0, 10)}
                content={item.meta.content}
                image={item.meta.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Link to="/blog">
      <button className="border border-blue-700 text-blue-700 px-4 py-2 rounded-lg hover:bg-blue-700 hover:text-white transition">
        View all blogs
      </button>
      </Link>
    </section>
  );
};

export default Blog;


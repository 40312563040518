import React from "react";
import logo from "../../assets/img/birtamod.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="bg-gradient-to-t from-[rgb(0,41,89)] to-sky-500 flex flex-col justify-between">
      <header className="md:flex justify-between items-center px-12 py-4 border-b-2 border-white md:gap-4 md:pb-2">
        <div className="flex items-center justify-center">
          <img src={logo} alt="Logo" className="h-10 md:h-20" />
        </div>
        <nav className="flex md:gap-24 items-center gap-4">
          <Link
            to="/"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-lg hover:underline md:text-lg">
              Home
            </a>
          </Link>
          <Link
            to="/about"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-lg hover:underline md:text-lg">
              About Us
            </a>
          </Link>
          <Link
            to="/blog"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-lg hover:underline md:text-lg">
              Blogs
            </a>
          </Link>
          <Link
            to="/signin"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white border-s-indigo-800 text-lg hover:underline md:text-lg">
              Login
            </a>
          </Link>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
// import React, { useState } from 'react';
// import logo from "../../assets/img/birtamod.png";
// import { Link } from "react-router-dom";
// import { FiMenu, FiX } from "react-icons/fi"; // For hamburger menu

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="bg-gradient-to-t from-[rgb(0,41,89)] to-sky-500">
//       <header className="flex justify-between items-center px-4 py-4 md:px-12 md:py-6">
//         {/* Logo Section */}
//         <div className="flex-shrink-0">
//           <img src={logo} alt="Logo" className="h-10 md:h-16" />
//         </div>

//         {/* Hamburger Menu for Mobile */}
//         <div className="md:hidden">
//           <button
//             className="text-white text-2xl"
//             onClick={toggleMenu}
//             aria-label="Toggle Menu"
//           >
//             {isOpen ? <FiX /> : <FiMenu />}
//           </button>
//         </div>

//         {/* Navigation Links */}
//         <nav
//           className={`${
//             isOpen ? "block" : "hidden"
//           } absolute top-full left-0 w-full md:relative md:flex md:items-center md:w-auto`}
//         >
//           <ul className="flex flex-col items-center space-y-4 py-4 md:flex-row md:space-y-0 md:space-x-8 md:py-0">
//             <li>
//               <Link
//                 to="/"
//                 className="text-white text-lg hover:underline"
//               >
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/about"
//                 className="text-white text-lg hover:underline"
//               >
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/blog"
//                 className="text-white text-lg hover:underline"
//               >
//                 Blogs
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/signin"
//                 className="text-white text-lg hover:underline"
//               >
//                 Login
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       </header>
//     </div>
//   );
// };

// // export default Navbar;
// import React, { useState } from 'react';
// import logo from "../../assets/img/birtamod.png";
// import { Link } from "react-router-dom";
// import { FiMenu, FiX } from "react-icons/fi"; // For hamburger menu

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="bg-gradient-to-t from-[rgb(0,41,89)] to-sky-500">
//       <header className="flex justify-between items-center px-4 py-4 md:px-12 md:py-6">
//         {/* Logo Section */}
//         <div className="flex-shrink-0">
//           <img src={logo} alt="Logo" className="h-10 md:h-16" />
//         </div>

//         {/* Hamburger Menu for Mobile */}
//         <div className="md:hidden">
//           <button
//             className="text-white text-2xl"
//             onClick={toggleMenu}
//             aria-label="Toggle Menu"
//           >
//             {isOpen ? <FiX /> : <FiMenu />}
//           </button>
//         </div>

//         {/* Navigation Links */}
//         <nav
//           className={`${
//             isOpen ? "block" : "hidden"
//           }gap-16 w-full absolute top-full left-0 md:relative md:flex md:items-center md:space-x-12`}
//         >
//           <ul className="flex flex-col items-center space-y-4 py-4 md:flex-row md:space-y-0 md:py-0">
//             <li>
//               <Link
//                 to="/"
//                 className="text-white text-lg hover:underline"
//                 onClick={() => setIsOpen(false)} // Close menu on click
//               >
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/about"
//                 className="text-white text-lg hover:underline"
//                 onClick={() => setIsOpen(false)} // Close menu on click
//               >
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/blog"
//                 className="text-white text-lg hover:underline"
//                 onClick={() => setIsOpen(false)} // Close menu on click
//               >
//                 Blogs
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/signin"
//                 className="text-white text-lg hover:underline"
//                 onClick={() => setIsOpen(false)} // Close menu on click
//               >
//                 Login
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       </header>
//     </div>
//   );
// };

// export default Navbar;

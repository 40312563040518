import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../Card/Card';
import { apiURL } from '../../api/apiService';

const Blogs = () => {

  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get(`${apiURL}feeds/home-feed`)
      .then(response => {
        setNews(response.data.blogs);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <section className="text-center my-10">
      <div className="mx-auto mb-10">
        <h2 className="text-2xl font-bold mb-4">Blogs</h2>
        <p className="text-gray-500 max-w-2xl mx-auto">
          Football related news and information
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-4 px-6 my-6">
        {news.map((item, index) => (
          <Card
            key={index}
            title={item.title.rendered}
            date={item.date.slice(0,10)}           
            content={item.meta.content}
            image={item.meta.image}
          />
        ))}
      </div>
      
    </section>
  );
};

export default Blogs;


import Hero from './components/Homebanner/Hero';
import Blogs from './components/Pages/Blogs';
import WhoWeAre from './components/Homebanner/WhoWeAre';
import ContactForm from './components/Homebanner/ContactForm';
import Footer from './components/Homebanner/Footer';
import Navbar from './components/Homebanner/Navbar';
import SignIn from './components/Pages/SignIn';
import SignUp from './components/Pages/SignUp';
import { Route, Routes } from "react-router-dom";
import "./index.css";




function App() {

  return (
    <>
    
      
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<WhoWeAre />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      
      <Footer/>
    </>
  )
}

export default App

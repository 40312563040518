

import React from "react";

const Card = ({ title, content, image }) => {
  return (
    <div className="card bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:-translate-y-2 w-[357px]">
      <img
        src={image}
        alt={title}
        className="w-full h-[200px] object-cover"
      />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-base text-gray-600 mb-2 block-content" >{content}</p>
        {/* <a
          href="#learn-more"
          className="text-blue-500 hover:underline text-base"
        >
          Learn more →
        </a> */}
      </div>
    </div>
  );
};

export default Card;

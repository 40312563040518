
import React from "react";
import { FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import Logomark from "../../assets/img/birtamod.png";
import cc from "../../assets/img/cc.png";
import footerimage from "../../assets/img/footerimage.png";

const Footer = () => {
  return (
    <section>
      <footer className="relative bg-cover bg-center bg-no-repeat text-white py-10 md:py-16" style={{ backgroundImage: `url('${footerimage}')` }}>
        <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,41,83,0.8)0%] to-[rgba(0, 45, 91, 0.8) 100%]"></div>
        <div className="relative z-10 flex flex-col md:flex-row justify-between items-start px-8 md:px-24">
          <div className="flex-1 mb-8 md:mb-0 max-w-sm">
            <div className="mb-4">
              <img src={Logomark} alt="Logomark" className="h-12" />
            </div>
            <p className="text-gray-300 mb-6">
              We are a collaboration of non-government organisations and
              professionals working to ensure that government spending.
            </p>
            <div className="flex gap-5 text-2xl">
              <FaTwitter />
              <FaLinkedin />
              <FaYoutube />
            </div>
          </div>
          <div className="flex-1 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h4 className="text-lg font-bold mb-4">Links</h4>
              <ul className="space-y-2">
                <li>Campaigns</li>
                <li>Resources</li>
                <li>News</li>
                <li>About Us</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-bold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li>Transparency Toolkit</li>
                <li>Evidence</li>
                <li>Best Practices</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-bold mb-4">Work with Us</h4>
              <ul className="space-y-2">
                <li>Contact Us</li>
                <li>Join Us</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-blue-800 text-white py-5 flex flex-col md:flex-row justify-between items-center px-8 md:px-24">
        <div>Terms and Conditions 
          <div className="text-sm">Copyright © 2024</div>
        </div>
        <div className="flex items-center gap-4 mt-4 md:mt-0">
        
          <IoArrowUpCircleOutline className="text-2xl cursor-pointer" />
        </div>
      </div>
    </section>
  );
};

export default Footer;
